import { render, staticRenderFns } from "./rates-table.vue?vue&type=template&id=76c72136&scoped=true&"
import script from "./rates-table.vue?vue&type=script&lang=ts&"
export * from "./rates-table.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76c72136",
  null
  
)

export default component.exports